<template>
    <div class="text">
        <div class="firstheading">{{title }}</div>
        <div class="secondheading">{{subtitle}}</div>
        <div class="tags">选择下面的标签(每次最多选填三个)</div>
    </div>
</template>

<script>
export default {
  name: 'TopText',
  props: ['title', 'subtitle']
}
</script>

<style lang="scss" scoped>
.text {

    .firstheading {
        position: relative;
        padding: 30px 35px 0 40px;
        font-size: 25px;
    }

    .firstheading::before {
        content: "";
        width: 8px;
        height: 25px;
        position: absolute;
        left: 20px;
        top: 75%;
        transform: translateY(-50%);
        margin-left: 2px;
        border-radius: 5px;
        background: #FF8563;
    }

    .secondheading {
        margin: 10px 40px;
        font-size: 20px;
    }

    .tags {
        margin: 5px 40px;
        font-size: 15px;
        font-weight: 500;
        color: #FF8563;
    }
}
</style>
